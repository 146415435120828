@import url("//hello.myfonts.net/count/41370a");

@font-face {
  font-family: "Recoleta-Medium";
  src: url('fonts/recoleta/font.woff2') format('woff2'), url('fonts/recoleta/font.woff') format('woff');
  font-weight: normal;
}

@font-face {
  font-family: 'strawfordregular';
  src: url('fonts/strawford/strawford-regular-webfont.eot');
  src: url('fonts/strawford/strawford-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('fonts/strawford/strawford-regular-webfont.woff2') format('woff2'),
  url('fonts/strawford/strawford-regular-webfont.woff') format('woff'),
  url('fonts/strawford/strawford-regular-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: strawfordregular, Recoleta-Medium, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

code {
  font-family: strawfordregular, Recoleta-Medium, serif;
}
